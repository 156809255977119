<template>
  <!-- counter -->
  <div>
    <v-container grid-list-lg fluid>
      <v-row>
        <v-col cols="4">
          <div class="carder">
            <div class="flex pa-3">
              <div class="pa-3 plum round">
                <v-icon size="50">mdi-store</v-icon>
              </div>
              <div class="flex flex-column my-auto">
                <h2 class="blue-grey--text nunito lighten-3 flex justify-end">
                  Total Merchant
                </h2>
                <div
                  class="text-h4 font-weight-bold flex justify-end lato"
                  id="num1"
                >
                  {{ this.total || 0 }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="carder">
            <div class="flex pa-3">
              <div class="pa-3 rose round">
                <v-icon size="50">mdi-account</v-icon>
              </div>
              <div class="flex flex-column my-auto">
                <h2 class="blue-grey--text nunito lighten-3 flex justify-end">
                  Total Active Merchant
                </h2>
                <div
                  class="text-h4 font-weight-bold flex justify-end lato"
                  id="num2"
                >
                  {{ this.active_merchant || 0 }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="carder">
            <div class="flex pa-3">
              <div class="pa-3 pale round">
                <v-icon size="50">mdi-chart-line</v-icon>
              </div>
              <div class="flex flex-column my-auto">
                <h2 class="blue-grey--text nunito lighten-3 flex justify-end">
                  Daily Transaction
                </h2>
                <div
                  class="text-h4 font-weight-bold flex justify-end lato"
                  id="num3"
                >
                  {{ this.daily_trans || 0 }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7">
          <div class="carder" v-if="stats">
            <apexchart
              width="100%"
              height="200"
              type="area"
              :options="options"
              :series="transaction_series"
            ></apexchart>
            <h2 class="blue-grey--text nunito lighten-3 px-2">
              Transaction Activity
            </h2>
            <p class="text-subtitle blue-grey--text darken-4 lato mb-2 px-3">
              Transaction from past week
            </p>
          </div>
          <div v-else>
            <v-skeleton-loader
              class="mx-auto"
              height="200"
              width="100%"
              type="card"
            ></v-skeleton-loader>
          </div>
        </v-col>
        <v-col cols="5">
          <div class="carder">
            <div v-if="stats">
              <apexchart
                height="212"
                type="donut"
                dark
                :options="chartOptions"
                :series="stats.delivery_by_provider"
              ></apexchart>
            </div>
            <div v-else>
              <v-skeleton-loader
                class="mx-auto"
                height="212"
                type="card"
              ></v-skeleton-loader>
            </div>
            <h2 class="blue-grey--text nunito lighten-3 px-2 mb-0">
              Delivery Activity
            </h2>
            <p class="text-subtitle blue-grey--text darken-4 lato mb-2 px-3">
              Delivery made from past week
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <div class="carder">
            <div class="flex pa-3">
              <div class="pa-3 plum round">
                <v-icon size="50">mdi-store</v-icon>
              </div>
              <div class="flex flex-column my-auto">
                <h2 class="blue-grey--text nunito lighten-3 flex justify-end">
                  Merchant V2
                </h2>
                <div
                  class="text-h4 font-weight-bold flex justify-end lato"
                  id="num1"
                >
                  {{ this.totalV2 || 0 }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="carder">
            <div class="flex pa-3">
              <div class="pa-3 rose round">
                <v-icon size="50">mdi-account</v-icon>
              </div>
              <div class="flex flex-column my-auto">
                <h2 class="blue-grey--text nunito lighten-3 flex justify-end">
                  Active Merchant V2
                </h2>
                <div
                  class="text-h4 font-weight-bold flex justify-end lato"
                  id="num2"
                >
                  {{ this.active_merchant_V2 || 0 }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="carder">
            <div class="flex pa-3">
              <div class="pa-3 pale round">
                <v-icon size="50">mdi-chart-line</v-icon>
              </div>
              <div class="flex flex-column my-auto">
                <h2 class="blue-grey--text nunito lighten-3 flex justify-end">
                  Daily Transaction V2
                </h2>
                <div
                  class="text-h4 font-weight-bold flex justify-end lato"
                  id="num3"
                >
                  {{ this.daily_trans_v2 || 0 }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <div class="carder">
            <div v-if="statsV2">
              <apexchart
                height="212"
                type="donut"
                dark
                :options="chartV2Billplz"
                :series="statsV2.v2_account_statistic_billplz"
              ></apexchart>
            </div>
            <div v-else>
              <v-skeleton-loader
                class="mx-auto"
                height="212"
                type="card"
              ></v-skeleton-loader>
            </div>
            <h2 class="blue-grey--text nunito lighten-3 px-2 mb-0">
              V2 Account Billplz Status
            </h2>
            <p class="text-subtitle blue-grey--text darken-4 lato mb-2 px-3">
              Verified, rejected and pending account for billplz verification.
            </p>
          </div>
        </v-col>
        <v-col cols="7">
          <div class="carder" v-if="stats">
            <apexchart
              width="100%"
              height="200"
              type="area"
              :options="options"
              :series="payout_activity"
            ></apexchart>
            <h2 class="blue-grey--text nunito lighten-3 px-2">
              Payout Activity
            </h2>
            <p class="text-subtitle blue-grey--text darken-4 lato mb-2 px-3">
              Number of completed payout from this week
            </p>
          </div>
          <div v-else>
            <v-skeleton-loader
              class="mx-auto"
              height="200"
              width="100%"
              type="card"
            ></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
import { mapGetters } from "vuex";

export default {
  name: "Main",

  components: {
    apexchart,
  },
  data() {
    return {
      labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
      value: [200, 675, 410, 390, 310, 460, 250, 240],
      options: {
        fill: {
          type: "gradient",
        },
        xaxis: {
          categories: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
        },
      },
      series: [
        {
          name: "sales",
          data: [30, 40, 35, 50, 49, 60, 70],
        },
      ],
      chartOptions: {
        labels: ["Speedy", "Bungkusit"],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartV2Billplz: {
        labels: ["Verified", "Rejected", "Pending"],
        chart: {
          type: "bar",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      testBar: {
        series: [
          {
            data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
          },
        ],
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [
            "South Korea",
            "Canada",
            "United Kingdom",
            "Netherlands",
            "Italy",
            "France",
            "Japan",
            "United States",
            "China",
            "Germany",
          ],
        },
      },
      logs: [
        {
          name: "Harry Potter",
          activity: "disturbs the Chambers of Secret",
          date: "20/4/2021",
          time: "2 days ago",
          icon: 2,
        },
        {
          name: "Frozen Yogurt",
          activity: "change Bungkusit API ID",
          date: "20/4/2021",
          time: "2 days ago",
          icon: null,
        },
        {
          name: "Frozen Yogurt",
          activity: "renew Premuim Subscription",
          date: "20/4/2021",
          time: "2 days ago",
          icon: null,
        },
        {
          name: "Frozen Yogurt",
          activity: "added Product to Inventory",
          date: "20/4/2021",
          time: "2 days ago",
          icon: null,
        },
        {
          name: "Frozen Yogurt",
          activity: "added new product Strawberry",
          date: "20/4/2021",
          time: "2 days ago",
          icon: null,
        },
        {
          name: "Ron",
          activity: "lose in chess",
          date: "19/4/2021",
          time: "3 days ago",
          icon: 4,
        },
      ],
      total: null,
      active_merchant: null,
      daily_trans: null,
      totalV2: null,
      active_merchant_V2: null,
      daily_trans_v2: null,
    };
  },

  computed: {
    ...mapGetters({
      stats: "getStats",
      statsV2: "getStatsV2",
    }),
    transaction_series() {
      return [
        {
          name: "Sales",
          data: this.stats.weekly_transaction_count,
        },
      ];
    },
    payout_activity() {
      return [
        {
          name: "Payout",
          data: this.statsV2.weekly_payout,
        },
      ];
    },
  },

  methods: {
    animateValue(id, start, end, duration) {
      if (start === end) return;
      var range = end - start;
      var current = start;
      var increment = end > start ? 1 : -1;
      var stepTime = Math.abs(Math.floor(duration / range));
      var obj = document.getElementById(id);
      var timer = setInterval(function () {
        current += increment;
        obj.innerHTML = current;
        if (current == end) {
          clearInterval(timer);
        }
      }, stepTime);
    },
  },

  beforeMount() {
    this.$store.dispatch("startLoad");
    this.$store.dispatch("fetchStatsV2").then((d) => {
      this.totalV2 = d.data.total_merchant_v2;
      this.active_merchant_V2 = d.data.active_merchants_v2;
      this.daily_trans_v2 = d.data.daily_transaction_v2;
    });
    this.$store.dispatch("fetchStats").then((d) => {
      // console.log("Stats : ", d);
      this.$store.dispatch("endLoad");
      if (!d.status) {
        this.isError = true;
      }
      this.total = d.data.total_merchants;
      this.active_merchant = d.data.active_merchants;
      this.daily_trans = d.data.daily_transaction;
      // this.animateValue("num1", 0, d.data.total_merchants, 3000);
      // this.animateValue("num2", 0, d.data.active_merchants, 3000);
      // this.animateValue("num3", 0, d.data.daily_transaction, 3000);
    });
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.height {
  height: 42vh;
  overflow-y: auto;
}
.plum {
  background-color: #dda0dd;
}
.rose {
  background-color: #fa8072;
}
.pale {
  background-color: #98fb98;
}
.round {
  border-radius: 50%;
}
.pad {
  margin-top: 3px;
}
.col {
  padding-top: 0px;
}

.w-75 {
  width: 75%;
}
</style>

<!-- <v-col cols="6">
          <v-container grid-list-lg class="carder height" fluid>
            <h2 class="blue-grey--text nunito lighten-3"><b>Logs</b></h2>
            <v-divider class="mb-2"></v-divider>
            <div class="mt-4">
              <v-row v-for="(item, i) in logs" :key="i">
                <v-col cols="1">
                  <v-avatar
                    size="54"
                    color="light-blue darken-1 white--text"
                    class="my-2"
                  >
                    <img
                      :src="
                        require(`../../../assets/users/users-${item.icon}.svg`)
                      "
                      :alt="item.name.charAt(0)"
                      v-if="item.icon"
                    />
                    <span v-else>{{ item.name.charAt(0) }}</span>
                  </v-avatar>
                </v-col>
                <v-col>
                  <div class="flex flex-column ml-4 nunito">
                    <span
                      ><b class="green--text">{{ item.name }}</b>
                      {{ item.activity }}</span
                    >
                    <span>
                      <v-icon>mdi-calendar-range</v-icon>
                      <span class="pl-2">{{ item.date }}</span>
                    </span>
                    <span>
                      <v-icon>mdi-clock-time-eight-outline</v-icon>
                      <span class="pl-2">{{ item.time }}</span>
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-col>
        <v-col cols="6">
          <v-container grid-list-lg class="carder height" fluid>
            <h2 class="blue-grey--text nunito lighten-3"><b>Online</b></h2>
            <v-divider class="mb-2"></v-divider>
            <div class="mt-4">
              <v-row v-for="(item, i) in logs" :key="i">
                <v-col cols="1">
                  <v-avatar
                    size="54"
                    color="light-blue darken-1 white--text"
                    class="my-2"
                  >
                    <img
                      :src="
                        require(`../../../assets/users/users-${item.icon}.svg`)
                      "
                      :alt="item.name.charAt(0)"
                      v-if="item.icon"
                    />
                    <span v-else>{{ item.name.charAt(0) }}</span>
                  </v-avatar>
                </v-col>
                <v-col>
                  <div class="ml-4 mt-6 nunito">
                    <span class=""
                      ><b class="green--text">{{ item.name }}</b></span
                    >
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-col> -->